:root {
  --main-bg: #1d67de;
}

.table tbody{
  font-size: 10px !important;
}

input:focus, button:focus {
  border: 1px solid var(--main-bg) !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--main-bg) !important;
  border-color: var(--main-bg) !important;
}

.card, .btn, input {
  border-radius: 0 !important;
}

.background-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('bg.jpg');
  background-size: cover;
  background-position: center;
}

.background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.content {
  z-index: 2;
}

.container {
  padding-top: 15px;
}